<div class="descriptionFlatGroundFloor">
  <p-card
    header="Ferienwohnung im Erdgeschoss"
    subheader="5 Personen finden hier Unterkunft. Ganzjährig, auf ca. 70 m².
  2 Schlafzimmer mit 2 bzw. 3 Betten, Wohnzimmer mit Kaminofen, Terrasse direkt am Wohnzimmer. Nichtraucher, keine Haustiere."
  >
    <div class="groundFloorImageGallery">
      <ng-image-slider
        [images]="imageObject"
        [manageImageRatio]="true"
        #nav
      ></ng-image-slider>
    </div>
    <div class="equipmentGroundFloor">
      <p-fieldset legend="Ausstattung" [toggleable]="true" [collapsed]="true">
        <div class="equipmentGroundFloorList">
          <ul>
            <li>Geschirrspüler</li>
            <li>Mikrowelle</li>
            <li>Backofen</li>
            <li>Kühl- und Gefrierkombination</li>
            <li>Waschmaschine</li>
            <li>Kaffeemaschine</li>
            <li>Toaster</li>
            <li>Sat-TV</li>
            <li>Kaminofen</li>
            <li>Dusche & WC</li>
            <li>Sauna</li>
            <li>Terrasse mit Gartenmöbeln</li>
            <li>WLAN</li>
          </ul>
          <strong>Bitte mitbringen:</strong>
          <ul>
            <li>Geschirrhandtücher</li>
            <li>Bettwäsche</li>
          </ul>
        </div>
      </p-fieldset>
    </div>
    <div class="pricingGroundFloor">
      <p-fieldset legend="Preise" [toggleable]="true" [collapsed]="true">
        <div class="pricingGroundFloorList">
          <ul>
            <li>
              <strong>Hauptsaison (29.06.2024 - 31.08.2024)</strong><br />
              1. Tag 140€, ab dem 2. Tag 110€ (entspricht 1 Woche = 800€)
              <br />
              In der Hauptsaison sind Buchungen nur wochenweise ab Samstag
              möglich.
            </li>
            <br />
            <li>
              <strong>Nebensaison (01.01.2024 - 28.06.2024 & 01.09.2024 - 31.12.2024)</strong><br />
              1. Tag 120€, ab dem 2. Tag 90€ (entspricht 1 Woche = 660€)
            </li>
            <br />
            <li>
              <strong>Nebenkosten</strong><br />
              Strom 0,45 € pro kWh
              <br />
              Kaution 100€
              <br />Wäschepaket auf Anfrage: Handtücher (1x groß + 1x klein) +
              Duschvorleger und Bettwäsche = 15€ pro Person
            </li>
          </ul>
          <br />
          <strong>Endreinigung ist inklusive!</strong>
        </div>
      </p-fieldset>
    </div>
  </p-card>
  <div id="calendarGroundFloor">
    <p-card
      header="Verfügbarkeit und Buchung bei Traum-Ferienwohnungen"
      subheader="Sie können die Ferienwohnung direkt über Traum-Ferienwohnungen buchen.
      Klicken Sie dazu einfach auf den Link, welcher unter dem Belegungsplan
      angezeigt wird."
    >
      <a id="calendar-link-11205" href="https://traum-ferienwohnungen.de/11205"
        >Ferienwohnung im Erdgeschoß auf traum-ferienwohnungen.de</a
      >
      <div
        id="traumfewo-calendar-11205"
        class="tfw-calendar-app"
        data-base_url="www.traum-ferienwohnungen.de"
        data-language_id="1"
        data-startMonth="0"
        data-numberOfMonths="6"
        data-listingid="11205"
      ></div>
    </p-card>
  </div>

  <div>
    <p-card
      header="Buchung bei Ferienhausmiete"
      subheader="Sie können die Ferienwohnung direkt über das Portal Ferienhausmiete.de buchen.
      Klicken Sie dazu einfach auf den unteren Link."
    >     
    <a href="https://www.ferienhausmiete.de/20294.htm">Erdgeschoßwohnung in Pruchten</a>
    </p-card>
  </div>
</div>

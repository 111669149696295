import { Component, OnInit } from '@angular/core';
import { PhotoServiceService } from '../service/photo-service.service';
import { window } from 'src/assets/scripts/calendarWidget.js';

declare let window: any;

@Component({
  selector: 'app-ground-floor',
  templateUrl: './ground-floor.component.html',
  styleUrls: ['./ground-floor.component.css'],
})
export class GroundFloorComponent implements OnInit {
  imageObject: Array<object> = [
    {
      image: 'assets/flat-photos/ground-floor/ferienwohnung_08.jpg',
      thumbImage: 'assets/flat-photos/ground-floor/ferienwohnung_08.jpg',
      title: 'Das Gebäude von außen',
      alt: 'Das Gebäude von außen',
    },
    {
      image: 'assets/flat-photos/ground-floor/fewo_unten_01.jpg',
      thumbImage: 'assets/flat-photos/ground-floor/fewo_unten_01.jpg',
      alt: 'Holzterrasse',
      title: 'Holzterrasse',
    },
    {
      image: 'assets/flat-photos/ground-floor/fewo_unten_02.jpg',
      thumbImage: 'assets/flat-photos/ground-floor/fewo_unten_02.jpg',
      title: 'Eingangsbereich - Flur',
      alt: 'Eingangsbereich - Flur',
    },
    {
      image: 'assets/flat-photos/ground-floor/fewo_unten_03.jpg',
      thumbImage: 'assets/flat-photos/ground-floor/fewo_unten_03.jpg',
      title: 'Wohnzimmer',
      alt: 'Wohnzimmer',
    },
    {
      image: 'assets/flat-photos/ground-floor/fewo_unten_07.jpg',
      thumbImage: 'assets/flat-photos/ground-floor/fewo_unten_07.jpg',
      title: 'Wohnzimmer',
      alt: 'Wohzimmer',
    },
    {
      image: 'assets/flat-photos/ground-floor/fewo_unten_10.jpg',
      thumbImage: 'assets/flat-photos/ground-floor/fewo_unten_10.jpg',
      title: 'Wohnzimmer - Essbereich',
      alt: 'Wohnzimmer - Essbereich',
    },
    {
      image: 'assets/flat-photos/ground-floor/fewo_unten_06.jpg',
      thumbImage: 'assets/flat-photos/ground-floor/fewo_unten_06.jpg',
      title: 'Schlafzimmer 1',
      alt: 'Schlafzimmer 1',
    },
    {
      image: 'assets/flat-photos/ground-floor/fewo_unten_05.jpg',
      thumbImage: 'assets/flat-photos/ground-floor/fewo_unten_05.jpg',
      title: 'Schlafzimmer 2',
      alt: 'Schlafzimmer 2',
    },
    {
      image: 'assets/flat-photos/ground-floor/fewo_unten_09.jpg',
      thumbImage: 'assets/flat-photos/ground-floor/fewo_unten_09.jpg',
      title: 'Küche',
      alt: 'Küche',
    },
    {
      image: 'assets/flat-photos/ground-floor/fewo_unten_08.jpg',
      thumbImage: 'assets/flat-photos/ground-floor/fewo_unten_08.jpg',
      title: 'Badezimmer',
      alt: 'Badezimmer',
    },
    {
      image: 'assets/flat-photos/ground-floor/fewo_unten_04.jpg',
      thumbImage: 'assets/flat-photos/ground-floor/fewo_unten_04.jpg',
      title: 'Badezimmer mit Sauna',
      alt: 'Badezimmer mit Sauna',
    },
    {
      image: 'assets/flat-photos/ground-floor/fewo_unten_11.jpg',
      thumbImage: 'assets/flat-photos/ground-floor/fewo_unten_11.jpg',
      title: 'Sauna',
      alt: 'Sauna',
    },
    {
      image: 'assets/flat-photos/ground-floor/pruchten_grundriss_eg.jpg',
      thumbImage: 'assets/flat-photos/ground-floor/pruchten_grundriss_eg.jpg',
      title: 'Grundriss',
      alt: 'Grundriss',
    },
    // {
    //   image: 'assets/flat-photos/ground-floor/pruchten_grundriss_eg.jpg',
    //   thumbImage: 'assets/flat-photos/ground-floor/pruchten_grundriss_eg.jpg',
    //   title: 'Grundriss',
    //   alt: 'Grundriss',
    // },
  ];

  constructor() {}

  ngOnInit() {
    // setTimeout(() => {
    //   this.createWidget();
    // }, 1000);
    this.createWidget();
  }

  async createWidget() {
    function createWidget11205() {
      var calendarNode = document.getElementById('traumfewo-calendar-11205');
      var calendarWidget = new window.tfw.CalendarWidget(calendarNode);
      calendarWidget.throttle('resize', 'optimizedResize');
      window.addEventListener(
        'resize',
        function () {
          calendarWidget.update();
        },
        false
      );
      calendarWidget.init();
    }
    window.addEventListener(
      'load',
      function (event: any) {
        createWidget11205();
      },
      false
    );
    if (window.tfw && window.tfw.CalendarWidget) {
      createWidget11205();
    }
  }
}

<div class="descriptionFlatUpperFloor">
  <p-card
    header="Ferienwohnung im Dachgeschoss"
    subheader="4 Personen finden hier Unterkunft, ganzjährig, auf ca. 60 m².
    2 Schlafzimmer, Wohnzimmer mit Schlafsofa. Nichtraucher, keine Haustiere."
  >
    <div class="upperFloorImageGallery">
      <ng-image-slider
        [images]="imageObject"
        [manageImageRatio]="true"
        #nav
      ></ng-image-slider>
    </div>
    <div class="equipmentUpperFloor">
      <p-fieldset legend="Ausstattung" [toggleable]="true" [collapsed]="true">
        <div class="equipmentUpperFloorList">
          <ul>
            <li>Geschirrspüler</li>
            <li>Mikrowelle</li>
            <li>Backofen</li>
            <li>Kühl- und Gefrierkombination</li>
            <li>Waschmaschine</li>
            <li>Kaffeemaschine</li>
            <li>Toaster</li>
            <li>Sat-TV, DVD, Spiele</li>
            <li>Kaminofen</li>
            <li>Dusche & WC</li>
            <li>Gartenmöbel</li>
            <li>WLAN</li>
          </ul>
          <strong>Bitte mitbringen:</strong>
          <ul>
            <li>Geschirrhandtücher</li>
            <li>Bettwäsche</li>
          </ul>
        </div>
      </p-fieldset>
    </div>
    <div class="pricingUpperFloor">
      <p-fieldset legend="Preise" [toggleable]="true" [collapsed]="true">
        <div class="pricingUpperFloorList">
          <ul>
            <li>
              <strong>Hauptsaison (29.06.2024 - 31.08.2024)</strong><br />
              1. Tag 130€,-, ab dem 2. Tag 100.-€ (entspricht 1 Woche = 730.-€)
              <br />
              In der Hauptsaison sind Buchungen nur wochenweise ab Samstag
              möglich.
            </li>
            <br />
            <li>
              <strong>Nebensaison (01.01.2024 - 28.06.2024 & 01.09.2024 - 31.12.2024)</strong><br />
              1. Tag 110,-€, ab dem 2. Tag 70,-€ (entspricht 1 Woche = 530,-€)
            </li>
            <br />
            <li>
              <strong>Nebenkosten</strong><br />
              Strom 0,45 € pro kWh
              <br />
              Kaution 100€
              <br />Wäschepaket auf Anfrage: Handtücher (1x groß + 1x klein) +
              Duschvorleger und Bettwäsche = 15€ pro Person
            </li>
          </ul>
          <br />
          <strong>Endreinigung ist inklusive!</strong>
        </div>
      </p-fieldset>
    </div>
  </p-card>
  <div id="calendarUpperFloor">
    <p-card
      header="Verfügbarkeit und Buchung bei Traum-Ferienwohnungen.de"
      subheader="Sie können die Ferienwohnung direkt über Traum-Ferienwohnungen buchen.
      Klicken Sie dazu einfach auf den Link, welcher unter dem Belegungsplan
      angezeigt wird."
    >
      <div
        id="traumfewo-calendar-11216"
        class="tfw-calendar-app"
        data-base_url="www.traum-ferienwohnungen.de"
        data-language_id="1"
        data-startMonth="0"
        data-numberOfMonths="6"
        data-listingid="11216"
      >
        <a
          id="calendar-link-11216"
          href="https://traum-ferienwohnungen.de/11216"
          >Ferienwohnung im Dachgeschoss auf traum-ferienwohnungen.de</a
        >
      </div>
    </p-card>
  </div>
</div>

<mat-sidenav-container class="sidenav-container">
  <!-- when it's handset, choose handset-mode (responsive) -->
  <mat-sidenav
    #drawer
    class="sidenav"
    [ngClass]="{ hidden: !(isHandset$ | async) }"
    fixedInViewport
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="(isHandset$ | async) === false"
  >
    <mat-toolbar class="menuBar">
      Menü
    </mat-toolbar>
    <mat-nav-list color="primary">
      <a mat-list-item [routerLink]="['/']">Startseite</a>
      <a mat-list-item [routerLink]="['/flat-ground-floor']">Ferienwohnung EG</a>
      <a mat-list-item [routerLink]="['/flat-upper-floor']">Ferienwohnung DG</a>
      <a mat-list-item [routerLink]="['/flat-binar-zingst']">Kleines Ferienhaus in Zingst/Darß</a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="primary">
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
        *ngIf="isHandset$ | async"
      >
      Menü <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <!-- when it's not handset, choose "normal"-mode without sidenav -->
      <div class="menuMain">
        <div [ngClass]="{ hidden: isHandset$ | async }">
          <button id="menuButton" mat-button [matMenuTriggerFor]="menu">Menü <mat-icon aria-label="Side nav toggle icon">menu</mat-icon></button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item><a class="buttonMenuLinks" mat-list-item [routerLink]="['/']">Startseite</a></button>
            <button mat-menu-item><a class="buttonMenuLinks" mat-list-item [routerLink]="['/flat-ground-floor']">Ferienwohnung EG</a></button>
            <button mat-menu-item><a class="buttonMenuLinks" mat-list-item [routerLink]="['/flat-upper-floor']">Ferienwohnung DG</a></button>
            <button mat-menu-item><a class="buttonMenuLinks" mat-list-item [routerLink]="['/flat-binar-zingst']">Kleines Ferienhaus in Zingst/Darß</a></button>
          </mat-menu>
        </div>
      </div>
      <span class="spacer"></span>
      <span id="companyName"><a [routerLink]="['/']">Ferien am Darß</a></span>
    </mat-toolbar>
    <ng-content></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>


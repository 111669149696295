<app-main-nav>
  <router-outlet></router-outlet>
  <div class="footer">
    <p-card>
      <a [routerLink]="['/imprint']" class="imprint">Impressum</a>
      <a [routerLink]="['/dataProtectionNotice']" class="privacyNotice">Datenschutzhinweis</a>
    </p-card>
  </div>
</app-main-nav>


import { Component, OnInit } from '@angular/core';
import { window } from 'src/assets/scripts/calendarWidget.js';

declare let window: any;

@Component({
  selector: 'app-upper-floor',
  templateUrl: './upper-floor.component.html',
  styleUrls: ['./upper-floor.component.css'],
})
export class UpperFloorComponent implements OnInit {
  imageObject: Array<object> = [
    {
      image: 'assets/flat-photos/upper-floor/ferienwohnung_08.jpg',
      thumbImage: 'assets/flat-photos/upper-floor/ferienwohnung_08.jpg',
      title: 'Das Gebäude von außen',
      alt: 'Das Gebäude von außen',
    },
    {
      image: 'assets/flat-photos/upper-floor/ferienwohnung_15.jpg',
      thumbImage: 'assets/flat-photos/upper-floor/ferienwohnung_15.jpg',
      alt: 'Wohnzimmer mit Kaminofen',
      title: 'Wohnzimmer mit Kaminofen',
    },
    {
      image: 'assets/flat-photos/upper-floor/ferienwohnung_02.jpg',
      thumbImage: 'assets/flat-photos/upper-floor/ferienwohnung_02.jpg',
      alt: 'Wohnzimmer - Essbereich',
      title: 'Wohnzimmer - Essbereich',
    },
    {
      image: 'assets/flat-photos/upper-floor/ferienwohnung_03.jpg',
      thumbImage: 'assets/flat-photos/upper-floor/ferienwohnung_03.jpg',
      alt: 'Wohnzimmer mit Schlafsofa',
      title: 'Wohnzimmer mit Schlafsofa',
    },
    {
      image: 'assets/flat-photos/upper-floor/ferienwohnung_04.jpg',
      thumbImage: 'assets/flat-photos/upper-floor/ferienwohnung_04.jpg',
      alt: 'Schlafzimmer 1',
      title: 'Schlafzimmer 1',
    },
    {
      image: 'assets/flat-photos/upper-floor/ferienwohnung_01.jpg',
      thumbImage: 'assets/flat-photos/upper-floor/ferienwohnung_01.jpg',
      alt: 'Schlafzimmer 2 mit Kinderbett',
      title: 'Schlafzimmer 2 mit Kinderbett',
    },
    {
      image: 'assets/flat-photos/upper-floor/ferienwohnung_05.jpg',
      thumbImage: 'assets/flat-photos/upper-floor/ferienwohnung_05.jpg',
      alt: 'Küche',
      title: 'Küche',
    },
    {
      image: 'assets/flat-photos/upper-floor/ferienwohnung_06.jpg',
      thumbImage: 'assets/flat-photos/upper-floor/ferienwohnung_06.jpg',
      alt: 'Badezimmer',
      title: 'Badezimmer',
    },
    {
      image: 'assets/flat-photos/upper-floor/ferienwohnung_07.jpg',
      thumbImage: 'assets/flat-photos/upper-floor/ferienwohnung_07.jpg',
      alt: 'Badezimmer',
      title: 'Badezimmer',
    },
    {
      image: 'assets/flat-photos/upper-floor/terrasse_oben_16.jpg',
      thumbImage: 'assets/flat-photos/upper-floor/terrasse_oben_16.jpg',
      alt: 'Terrasse',
      title: 'Terrasse',
    },
    {
      image: 'assets/flat-photos/upper-floor/pruchten_grundriss_og.jpg',
      thumbImage: 'assets/flat-photos/upper-floor/pruchten_grundriss_og.jpg',
      alt: 'Grundriss - OG',
      title: 'Grundriss - OG',
    },
  ];

  constructor() {}

  ngOnInit(): void {
    // setTimeout(() => this.createWidget(), 1000)
    this.createWidget();

  }

  async createWidget() {
    function createWidget11216() {
      var calendarNode = document.getElementById('traumfewo-calendar-11216');
      var calendarWidget = new window.tfw.CalendarWidget(calendarNode);
      calendarWidget.throttle('resize', 'optimizedResize');
      window.addEventListener(
        'resize',
        function () {
          calendarWidget.update();
        },
        false
      );
      calendarWidget.init();
    }
    window.addEventListener(
      'load',
      function (event) {
        createWidget11216();
      },
      false
    );
    if (window.tfw && window.tfw.CalendarWidget) {
      createWidget11216();
    }
  }
}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { MainNavComponent } from './main-nav/main-nav.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatCardModule } from '@angular/material/card';
import { LayoutModule } from '@angular/cdk/layout';
import { MatMenuModule } from '@angular/material/menu';
import { GroundFloorComponent } from './flats/ground-floor/ground-floor.component';
import { UpperFloorComponent } from './flats/upper-floor/upper-floor.component';
import { FlatBinarZingstComponent } from './flats/flat-binar-zingst/flat-binar-zingst.component';
import { GalleriaModule } from 'primeng/galleria';
import { NgImageSliderModule } from 'ng-image-slider';
import { FrontPageComponent } from './front-page/front-page.component';
import { CardModule, Card } from 'primeng/card';
import { FieldsetModule } from 'primeng/fieldset';
import { AngularFireModule } from '@angular/fire';
import { environment } from '../environments/environment';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { ImprintComponent } from './imprint-and-dataProtection/imprint/imprint.component';
import { DataProtectionNoticeComponent } from './imprint-and-dataProtection/data-protection-notice/data-protection-notice.component';

@NgModule({
  declarations: [
    AppComponent,
    MainNavComponent,
    GroundFloorComponent,
    UpperFloorComponent,
    FlatBinarZingstComponent,
    FrontPageComponent,
    ImprintComponent,
    DataProtectionNoticeComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NoopAnimationsModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatCardModule,
    MatMenuModule,
    GalleriaModule,
    NgImageSliderModule,
    CardModule,
    FieldsetModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-flat-binar-zingst',
  templateUrl: './flat-binar-zingst.component.html',
  styleUrls: ['./flat-binar-zingst.component.css']
})
export class FlatBinarZingstComponent implements OnInit {

  imageObject: Array<object> = [
    {
      image: 'assets/flat-photos/binar-zingst/zingst_01.jpg',
      thumbImage: 'assets/flat-photos/binar-zingst/zingst_01.jpg',
      title: 'Das Gebäude von außen',
      alt: 'Das Gebäude von außen',
    },
    {
      image: 'assets/flat-photos/binar-zingst/zingst_02.jpg',
      thumbImage: 'assets/flat-photos/binar-zingst/zingst_02.jpg',
      title: 'Wohnbereich',
      alt: 'Wohnbereich',
    },
    {
      image: 'assets/flat-photos/binar-zingst/zingst_07.jpg',
      thumbImage: 'assets/flat-photos/binar-zingst/zingst_07.jpg',
      title: 'Wohnbereich',
      alt: 'Wohnbereich',
    },
    {
      image: 'assets/flat-photos/binar-zingst/zingst_03.jpg',
      thumbImage: 'assets/flat-photos/binar-zingst/zingst_03.jpg',
      title: 'Schlafzimmer im Spitzboden',
      alt: 'Schlafzimmer im Spitzboden',
    },
    {
      image: 'assets/flat-photos/binar-zingst/zingst_04.jpg',
      thumbImage: 'assets/flat-photos/binar-zingst/zingst_04.jpg',
      title: 'Badezimmer',
      alt: 'Badezimmer',
    },
    {
      image: 'assets/flat-photos/binar-zingst/zingst_06.jpg',
      thumbImage: 'assets/flat-photos/binar-zingst/zingst_06.jpg',
      title: 'Badezimmer',
      alt: 'Badezimmer',
    },
    {
      image: 'assets/flat-photos/binar-zingst/zingst_05.jpg',
      thumbImage: 'assets/flat-photos/binar-zingst/zingst_05.jpg',
      title: 'Zugang zum Spitzboden',
      alt: 'Zugang zum Spitzboden',
    },
  ];

  constructor() { }

  ngOnInit(): void {
  }

}

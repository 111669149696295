<div class="descriptionFlatBinarZingst">
  <p-card
    header="Ferienhaus direkt in Zingst/Darß"
    subheader="Ein kleines Ferienhaus, direkt im Zentrum von Zingst, für 2 - 3 Personen.
    Die Einrichtung ist einfach und zweckmäßig."
  >
    <p>
      <strong>Wichtig!</strong> Das Schlafzimmer ist im Spitzboden und nur über
      eine leiterartige Treppe zu erreichen, nicht geeignet für Gehbehinderte
      und Kleinkinder!<br />
      Anfragen für diese Ferienwohnung an Frau Katrin Binar:<br />
      katrinbinar@yahoo.de<br />
      Telefon: 0151 110 751 76 nach 19:00 Uhr
    </p>
    <div class="flatBinarImageGallery">
      <ng-image-slider
        [images]="imageObject"
        [manageImageRatio]="true"
        #nav
      ></ng-image-slider>
    </div>
    <div class="equipmentFlatBinar">
      <p-fieldset legend="Ausstattung" [toggleable]="true" [collapsed]="true">
        <div class="equipmentFlatBinarList">
          <ul>
            <li>Wohnzimmer</li>
            <li>Küchenzeile im Flurbereich</li>
            <li>Bad mit Dusche und WC</li>
            <li>Schlafzimmer mit 2 Betten und einem Zustellbett</li>
            <li>Strandnähe (ca. 800m)</li>
            <li>Einkaufszentrum ca. 50m</li>
          </ul>
        </div>
      </p-fieldset>
    </div>
    <div class="pricingFlatBinar">
      <p-fieldset legend="Preise" [toggleable]="true" [collapsed]="true">
        <div class="pricingFlatBinar">
          <ul>
            <li>
              <strong>Ab 50€ pro Nacht</strong>
            </li>
          </ul>
        </div>
      </p-fieldset>
    </div>
  </p-card>
</div>

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainNavComponent } from './main-nav/main-nav.component';
import { GroundFloorComponent } from './flats/ground-floor/ground-floor.component';
import { FrontPageComponent } from './front-page/front-page.component';
import { UpperFloorComponent } from './flats/upper-floor/upper-floor.component';
import { FlatBinarZingstComponent } from './flats/flat-binar-zingst/flat-binar-zingst.component';
import { ImprintComponent } from './imprint-and-dataProtection/imprint/imprint.component';
import { DataProtectionNoticeComponent } from './imprint-and-dataProtection/data-protection-notice/data-protection-notice.component';


const routes: Routes = [
  { path: '', component: FrontPageComponent},
  { path: 'flat-ground-floor', component: GroundFloorComponent},
  { path: 'flat-upper-floor', component: UpperFloorComponent},
  { path: 'flat-binar-zingst', component: FlatBinarZingstComponent},
  { path: 'imprint', component: ImprintComponent},
  { path: 'dataProtectionNotice', component: DataProtectionNoticeComponent},
  { path: '*', component: FrontPageComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

<div class="main-div">
  <mat-card class="frontPageWithFlatPhoto">
    <div class="p-grid p-align-center">
      <div class="p-col-12 p-md-4 p-lg-4">
        <mat-card-content class="mainPageNextToImageDescription"
          >Ferienwohnungen am Darß - Ostsee</mat-card-content
        >
      </div>
      <div class="p-col-12 p-md-4 p-lg-4">
        <img
          mat-card-image
          id="flatMainPageImage"
          src="assets/flat-photos/flat_mainPage.jpg"
        />
      </div>
      <div class="p-col-12 p-md-4 p-lg-4">
        <mat-card-content class="mainPageNextToImageDescription"
          >Feriendorf "Claus Störtebeker"</mat-card-content
        >
      </div>
    </div>
  </mat-card>
  <div class="frontPageWithFlatPhotoDescription">
    <p-card
      header="Ferienwohnungen in Pruchten am Darß"
      subheader="Alle Wohnungen inkl. WLAN"
    >
      <div class="frontPageWithFlatPhotoDescriptionText">
        Direkt am Nationalpark Vorpommersche Boddenlandschaft gelegen und ca. 8
        km vom Ostseebad Zingst entfernt, liegen unsere beiden sehr schön mit
        Vollholzmöbeln eingerichteten Ferienwohnungen in einem Reihenendhaus.<br />
        <strong
          >Die Totalsanierung erfolgte 2005/2006 maßgeblich unter
          baubiologischen Gesichtspunkten! <br />
          Beide Wohnungen sind vom Deutschen Tourismusverband klassifiziert und
          mit je drei Sternen bewertet worden.</strong
        >
        <br />
        Eine Bademöglichkeit ist in wenigen Minuten direkt am Bodden zu
        erreichen. Jede Ferienwohnung ist separat mietbar. Auch für Kleingruppen
        und befreundete Familien (max. 10 Personen) sehr geeignet.<br />
      </div>
      <div>
        <p>
          <strong>Achtung: Ab 2022 erhebt die Gemeinde Pruchten eine Kurtaxe von 2,-€ pro Tag und Person ab 15 Jahre
          </strong>
        </p>
      </div>
      <mat-card-actions class="flatLinkingButtons">
        <div id="arrivalAndDepartureTimesForFlats">
          <strong>Für beide Wohnungen:</strong><br />
          Abreise bis 10.00 Uhr<br />
          Anreise ab 15.00 Uhr
        </div>
        <button
          mat-raised-button
          color="primary"
          id="flatLinkingButtonGroundFloor"
          [routerLink]="['/flat-ground-floor']"
        >
          Zur Wohnung im Erdgeschoss
        </button>
        <button
          mat-raised-button
          color="primary"
          id="flatLinkingButtonUpperFloor"
          [routerLink]="['/flat-upper-floor']"
        >
          Zur Wohnung im Dachgeschoss
        </button>
      </mat-card-actions>
    </p-card>
  </div>
  <mat-card class="googleMapsCard">
    <div class="googleMapsCardForBothFlats">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3116.963005865622!2d12.65004591524096!3d54.37280452443763!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47ac93fcc1028957%3A0x81f647208fe6d95b!2sZum%20St%C3%B6rtebeker%2C%2018356%20Pruchten!5e1!3m2!1sde!2sde!4v1591723402790!5m2!1sde!2sde"
        width="800"
        height="600"
        frameborder="0"
        style="border: 0;"
        allowfullscreen=""
        aria-hidden="false"
        tabindex="0"
      ></iframe>
    </div>
  </mat-card>
</div>
